<template>
    <div>
    <NavBar
      style="max-height: 50px; margin-bottom: 10px"
      :username="currentUserName"
      :pageName="pageName"
    ></NavBar>
    <v-app>
        <!-- add single Members Dialog starts -->
        <div>
            <v-row justify="center">
              <v-dialog v-model="addSingleMembersModal" persistent max-width="700">
                <v-card>
                  <v-card-title class="createCorporateDilogTitle">
                    <div>
                      <p style="font-weight:bold;margin-top:10px" >
                        Add Members
                        </p>
                    </div>
                    <div>
                      <v-btn  icon @click="addSingleMembersModal=false">
                      <img src="@/assets/popupClose.svg" >
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <div>
                        <div class="addSingleMemberMainClass">
                            <div class="titleAndTextFiledClass">
                                <div>
                                    <p style="text-align:left">Name<span style="color:red">*</span></p>
                                </div>
                                <div>
                                    <v-text-field
                                    v-model="nameOfMember"
                                    placeholder="Enter Name Here"
                                    solo
                                    >
                                    </v-text-field>
                                </div>
                            </div>
                            <div class="titleAndTextFiledClass">
                                <div>
                                    <p style="text-align:left">Phone Number<span style="color:red">*</span></p>
                                </div>
                                <div>
                                    <v-text-field
                                    v-model="phone_number"
                                    placeholder="Phone Number"
                                    solo
                                    >
                                    </v-text-field>
                                </div>
                            </div>
                        </div>
                        <div class="addSingleMemberMainClass">
                            <div class="titleAndTextFiledClass">
                                <div>
                                    <p style="text-align:left">Employee ID</p>
                                </div>
                                <div>
                                    <v-text-field
                                    v-model="employeeIdOfMember"
                                    placeholder="Enter Employee ID"
                                    solo
                                    >
                                    </v-text-field>
                                </div>
                            </div>
                            <!-- <div class="titleAndTextFiledClass" style="margin-right:115px">
                                <div>
                                    <p style="text-align:left">Date Of Birth</p>
                                </div>
                                <div>
                                    <input type="date" v-model="dobOfMember" background-color="#F1F6FF"/>
                                </div>
                            </div> -->
                        </div>
                        <div class="addSingleMemberMainClass">
                            <div class="titleAndTextFiledClass">
                                <div>
                                    <p style="text-align:left">Start Date<span style="color:red">*</span></p>
                                </div>
                                <div>
                                    <input type="date" v-model="startDateForMember" style="border-style: inset; border-color: #808080;" background-color="#F1F6FF"/>
                                </div>
                            </div>
                            <div class="titleAndTextFiledClass" style="margin-right:115px">
                                <div>
                                    <p style="text-align:left">End Date<span style="color:red">*</span></p>
                                </div>
                                <div>
                                    <input type="date" v-model="endDateForMember" style="border-style: inset; border-color: #808080;" background-color="#F1F6FF" />
                                </div>
                            </div>
                        </div>
                    </div>
                  </v-card-text>
                  <v-card-actions style="display:flex; justify-content:flex-end">
                    <div >
                      <v-btn
                      color="primary"
                      width="100px"
                      @click="createMember"
                      style="background-color: #1467BF;"
                      :disabled="isButtonClicked || (phone_number == '' || nameOfMember == '' || endDateForMember == '' || startDateForMember == '')"
                      >
                      Save
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
        </div>
        <!-- add single Members Dialog ends -->
        <div style="background-color: #f5faff; height: 94.5vh !important">
            <v-row cols="12">
                <v-col cols="2">
                    <SideBar style="max-height: 50px" :pageName="pageName"></SideBar>
                </v-col>
                <v-col cols="10">
                    <div style="margin:70px 40px 10px 40px">
                        <v-row style="margin-left: 5px" class="pageHeaderClass">
                            <div>
                                <span
                                style="font-weight: 800;
                                font-size: 12pt;
                                text-transform: uppercase;
                                letter-spacing: 1px;">
                                <h3>Add Members</h3>
                                </span>
                            </div>
                            <div class="memberlistPageAction">
                                <div>
                                    <v-text-field
                                        v-model="memberFilter"
                                        label="Search"
                                        dense
                                        style="margin-right:8px"
                                        solo
                                    >
                                        <template v-slot:append>
                                        <v-btn @click="" icon>
                                            <img
                                            style="width: 25px; height: 25px"
                                            src="https://s3iconimages.mymedicine.com.mm/search.svg"
                                            />
                                        </v-btn>
                                        </template>
                                    </v-text-field>
                                </div>
                                <div style="margin-right:8px">
                                    <v-btn color="primary" disabled style="background-color: #1467BF;">
                                        <img src="https://s3iconimages.mymedicine.com.mm/whiteUploadIcon.svg" > Upload
                                    </v-btn>
                                </div>
                                <div style="margin-right:8px; background-color: #1467BF;" >
                                    <v-btn color="primary" disabled>
                                        <img src="https://s3iconimages.mymedicine.com.mm/whiteUploadIcon.svg" > Download
                                    </v-btn>
                                </div>
                                <div style="margin-right:8px; background-color: #1467BF;">
                                    <v-btn color="primary" @click="addSingleMembersModal= true" >
                                        <img src="https://s3iconimages.mymedicine.com.mm/whiteUploadIcon.svg" > Add Members
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </div>
                    <v-data-table
                        v-if="tabledata.length > 0"
                        :headers="headers"
                        :items="tabledata"
                        class="corporatePackageTable">
                        <!-- <template slot="item.action">
                            <v-btn v-show="false" class="mx-2" icon @click="">
                                <img src="https://s3iconimages.mymedicine.com.mm/show_pwd.svg" style="height: 24px; width: 24px;"/>
                            </v-btn>
                            <v-btn v-show="false" class="mx-2" icon @click="">
                                <img src="https://s3iconimages.mymedicine.com.mm/edit.svg" />
                            </v-btn>
                        </template> -->
                    </v-data-table>
                    <div v-else align="center">
                        Add members to corporate package
                    </div>
                    <div  style="padding-right: 60px; padding-top: 30px;" align="right">
                        <v-btn class="warning" @click="ViewPackage" style="background-color: #1467BF;">View Package</v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-app>
    </div>
</template>
<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { handleError } from '../../../utils/utils';

export default {
    name: "AllCorporatePackages",
    data() {
        return {
            pageName: "",
            currentUser: "",
            currentUserName: "",
            headers: [
              {
                text: 'Staff ID',
                align: 'start',
                sortable: false,
                value: 'employeeIdOfMember',
              },
              { text: 'Name', value: 'nameOfMember' },
              { text: 'Phone Number', value: 'phone_number' },
              // { text: 'Date Of Birth', value: 'dobOfMember' },
              { text: 'Start Date', value: 'startDateForMember' },
              { text: 'End Date', value: 'endDateForMember' },
            ],
            tabledata:[
            ],
            addSingleMembersModal:true,
            nameOfMember:"",
            phone_number:"",
            employeeIdOfMember:"",
            // dobOfMember:"",
            startDateForMember:"",
            endDateForMember:"",
            memberFilter:"",
            createdBy:"",
            employeesList:[],
            packageID:"",
            isButtonClicked: false,
        }
    },
    components: {
        NavBar,
        SideBar,
    },
    mounted(){
        this.pageName = "AllCorporatePackages";
        this.currentUser = this.$cookies.get("token");
        if (!this.currentUser) {
          this.$router.push({
            name: "AdminLogin",
          });
        } else {
            var params = this.$route.params.package_id;
            this.packageID = params
            var getAdminBody ={
                    token : this.currentUser,
                    typeOfUser : "ADMIN",
                    adminType : "ADMIN"
                    }
                    axios.post(process.env.VUE_APP_BACKEND_URL+"/getAdmin",getAdminBody)
                    .then((getAdminResponse)=>{
                        this.createdBy = getAdminResponse.data.data._id
                    })
                    .catch((getAdminError)=>{
                        if (getAdminError.response.status == 401) {
                            window.alert("Sorry !! You are unauthorized !!");
                            this.$router.push({
                                name: "AdminLogin",
                            });
                        } else {
                            window.alert("Something went wrong...");
                        }
                    })
        }
    },
    methods:{
        ViewPackage()
        {
            this.$router.push({
                path: "/admin/ViewCorporatePackage/" + this.packageID
            });
        },
        createMember(){
            this.isButtonClicked = true;
            // this.employeesList.push({employee_id:this.employeeIdOfMember, employee_name:this.nameOfMember, phone_number:this.phone_number, date_of_birth:this.dobOfMember, start_date:this.startDateForMember, end_date: this.endDateForMember})
            this.employeesList.push({employee_id:this.employeeIdOfMember, employee_name:this.nameOfMember, phone_number:this.phone_number,start_date:this.startDateForMember, end_date: this.endDateForMember})
            var createMemberBody ={
                corporatePackageId:this.packageID,
                employeesList:this.employeesList,
                createdBy:this.createdBy,
                token : this.currentUser,
                typeOfUser : "ADMIN",
            };
            this.employeesList = [];
            axios.post(process.env.VUE_APP_BACKEND_URL+"/createListOfCorporateEmployees",createMemberBody)
            .then((createListOfCorporateEmployeesResponse)=>{
              console.log("createListOfCorporateEmployees",createListOfCorporateEmployeesResponse);
              window.alert("Added Employee");
              this.tabledata.push({
                'employeeIdOfMember': this.employeeIdOfMember,
                'nameOfMember': this.nameOfMember,
                'phone_number': this.phone_number,
                // 'dobOfMember': this.dobOfMember,
                'startDateForMember': this.startDateForMember,
                'endDateForMember': this.endDateForMember
              });
              this.nameOfMember = "";
              this.phone_number = "";
              this.employeeIdOfMember = "";
              // this.dobOfMember = "";
              this.startDateForMember = "";
              this.endDateForMember = "";
              this.isButtonClicked = false;
            })
            .catch((createListOfCorporateEmployeesError)=>{
              console.log("createListOfCorporateEmployeesError", createListOfCorporateEmployeesError);
              this.isButtonClicked = false;
              handleError(createListOfCorporateEmployeesError, this.$router, 'createListOfCorporateEmployeesError', 'AdminLogin', 'token');
            })
        }
    }
}
</script>
<style scoped>
.memberlistPageAction{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-right:20px;
}
.pageHeaderClass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.addSingleMemberMainClass{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.titleAndTextFiledClass{
    display: flex;
    flex-direction: column;
}
.createCorporateDilogTitle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>
<style>
    .corporatePackageTable .v-data-table-header th{
        background: #1467bf !important;
        color: white !important;
        font-size: 8pt !important;
        font-weight: 500 !important;
    }
    .corporatePackageTable tbody td{
        font-size: 8pt !important;
        font-weight: 700 !important;
    }
</style>
